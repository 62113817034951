import { baseTexts, i18nDays, SupportedLanguage } from "./i18n_base";
import { projectTexts } from "./i18n_project";

export const TextOption = {...baseTexts, ...projectTexts}
export {SupportedLanguage}

export default class I18N {
    lang: SupportedLanguage;
    constructor(lang: SupportedLanguage) {    
        this.lang = SupportedLanguage[lang.toString().toUpperCase() as any as SupportedLanguage] || SupportedLanguage.EN;
    }

    getText(text: keyof typeof TextOption) {
        return TextOption[text][this.lang];
    }

    static getText(options: { lang: SupportedLanguage, text: keyof typeof TextOption }) {
        return TextOption[options.text][options.lang];
    }

    getWeekday(index: number, length: 'short' | 'long') {        
        return i18nDays[length][this.lang][index];
    }

    static getPeriod(period: string): keyof typeof TextOption {
        let o: { [key: string]: keyof typeof TextOption } = {
            "daily": "Daily",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "quarterly": "Quarterly",
            "annual": "Annual",
        }

        return o[period] || "Period";
    }

}