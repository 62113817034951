// React
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';

// Vieolo UI
import Card from '@vieolo/vieolo-ui/dist/Card';
import Spacer from '@vieolo/vieolo-ui/dist/Spacer';
import Typography from '@vieolo/vieolo-ui/dist/Typography';

// Local UI
import MDXAnchor from './mdx_a';

// Utils and Logics
import { TextOption } from '../i18n';

export default function ContentDirectionCard(props: {
    title: string,
    direction: "pre" | "next",
    url: string
}) {

    let lang = useSelector((state: RootState) => state.app.language)
    let ta: 'right' | 'left' = props.direction === 'next' ? 'right' : 'left'

    return <MDXAnchor link={props.url} >
        <Card emphasis='low-normal' className='width--px-300 max-width--vw-40'>
            <Typography text={props.direction === 'next' ? TextOption.Next[lang] : TextOption.Previous[lang]} textAlign={ta} />
            <Spacer height='one' />
            <Typography text={props.title} type='title-small' textAlign={ta} />
        </Card>
    </MDXAnchor>
}