// React
import { useMemo } from 'react';
import '../style/help.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
// import { setInitialData } from '../app/appSlice';
// import { useHistory, useLocation } from 'react-router-dom';

// Vieolo UI
// import Typography from '@vieolo/vieolo-ui/dist/Typography';
// import Spacer from '@vieolo/vieolo-ui/dist/Spacer';
// import Divider from '@vieolo/vieolo-ui/dist/Divider';
// import Flex from '@vieolo/vieolo-ui/dist/Flex';
// import Card from '@vieolo/vieolo-ui/dist/Card';
// import Spinner from '@vieolo/vieolo-ui/dist/Spinner';
// import VieoloScaffold from '@vieolo/vieolo-ui/dist/VieoloScaffold';
// import Page from '@vieolo/vieolo-ui/dist/Page';
// import Button from '@vieolo/vieolo-ui/dist/Button';
// import DropDownMenu from '@vieolo/vieolo-ui/dist/DropDownMenu';
// import GridContainer from '@vieolo/vieolo-ui/dist/GridContainer';
// import Grid from '@vieolo/vieolo-ui/dist/Grid';
// import TreeList, { TreeListItem } from '@vieolo/vieolo-ui/dist/TreeList';

// Utils and Logics
import { TextOption } from '../i18n';

// Local UI
import ContentPage, { ContentRoute } from '../contents/content_page';



export default function HelpEntry(props: {}) {    
    
    let lang = useSelector((state: RootState) => state.app.language)

    let helpRoutes: ContentRoute[] = useMemo(() => {
        return [
            {
                id: "get_started",
                title: TextOption.GetStarted[lang],
                lastUpdated: "2022-12-24",
                children: [
                    {
                        id: "get_started/add_your_employees",
                        title: "1. " + TextOption.AddYourAdminEmployees[lang],
                        lastUpdated: "2022-12-24",
                    },
                    {
                        id: "get_started/add_your_customers",
                        title: "2. " + TextOption.AddYourCustomers[lang],
                        lastUpdated: "2022-12-24",
                    },
                    {
                        id: "get_started/add_your_trucks",
                        title: "3. " + TextOption.AddYourTrucks[lang],
                        lastUpdated: "2022-12-24",
                    },
                    {
                        id: "get_started/add_your_trailers",
                        title: "4. " + TextOption.AddYourTrailers[lang],
                        lastUpdated: "2022-12-24",
                    },
                    {
                        id: "get_started/add_your_drivers",
                        title: "5. " + TextOption.AddYourDrivers[lang],
                        lastUpdated: "2022-12-24",
                    },
                    {
                        id: "get_started/add_your_first_service",
                        title: "6. " + TextOption.AddYourFirstService[lang],
                        lastUpdated: "2022-12-24",
                    },
                    {
                        id: "get_started/next_steps",
                        title: "7. " + TextOption.NextSteps[lang],
                        lastUpdated: "2022-12-24",
                    },
                ]
            },
            {
                id: "services",
                title: TextOption.Services[lang],
                lastUpdated: "2022-12-24",
                children: [
                    {
                        id: "services/service_structure",
                        title: TextOption.ServiceStructure[lang],
                        lastUpdated: "2022-12-24",
                    },
                    {
                        id: "services/add_service",
                        title: TextOption.AddAService[lang],
                        lastUpdated: "2023-04-07",
                        children: [
                            {
                                id: "services/add_service/add_manually",
                                title: TextOption.AddManually[lang],
                                lastUpdated: "2023-04-07",
                                children: [
                                    {
                                        id: "services/add_service/add_manually/billing_date",
                                        title: TextOption.BillingDate[lang],
                                        lastUpdated: "2023-04-16",
                                    },
                                    {
                                        id: "services/add_service/add_manually/service_related",
                                        title: TextOption.CustomerDriverAndVehicles[lang],
                                        lastUpdated: "2023-04-16",
                                    },
                                    {
                                        id: "services/add_service/add_manually/driving_hours",
                                        title: TextOption.DrivingHours[lang],
                                        lastUpdated: "2023-04-16",
                                    },
                                    {
                                        id: "services/add_service/add_manually/hourly_rates",
                                        title: TextOption.HourlyRates[lang],
                                        lastUpdated: "2023-04-16",
                                    },
                                    {
                                        id: "services/add_service/add_manually/service_revenue",
                                        title: TextOption.ServiceRevenue[lang],
                                        lastUpdated: "2023-04-16",
                                    },
                                    {
                                        id: "services/add_service/add_manually/notes",
                                        title: TextOption.Notes[lang],
                                        lastUpdated: "2023-04-16",
                                    },
                                    {
                                        id: "services/add_service/add_manually/add_ride",
                                        title: TextOption.AddARide[lang],
                                        lastUpdated: "2023-04-16",
                                    },
                                    {
                                        id: "services/add_service/add_manually/add_stop",
                                        title: TextOption.AddAStop[lang],
                                        lastUpdated: "2023-04-16",
                                    },
                                    {
                                        id: "services/add_service/add_manually/attach_file",
                                        title: TextOption.AttachAFile[lang],
                                        lastUpdated: "2023-04-16",
                                    },
                                ]
                            },
                            {
                                id: "services/add_service/add_via_file",
                                title: TextOption.AddViaFile[lang],
                                lastUpdated: "2022-12-24",
                            },
                            {
                                id: "services/add_service/add_via_templates",
                                title: TextOption.AddViaTemplates[lang],
                                lastUpdated: "2022-12-24",
                            },
                            {
                                id: "services/add_service/plan_using_location",
                                title: TextOption.PlanUsingLocation[lang],
                                lastUpdated: "2022-12-24",
                            },
                        ]
                    },
                    {
                        id: "services/edit_service",
                        title: TextOption.EditAService[lang],
                        lastUpdated: "2022-12-24",
                    },
                    {
                        id: "services/assignment_pdf",
                        title: TextOption.AssignmentPDF[lang],
                        lastUpdated: "2022-12-24",
                    },
                    {
                        id: "services/review_and_verification",
                        title: TextOption.ReviewAndVerification[lang],
                        lastUpdated: "2022-12-24",
                    },
                    {
                        id: "services/delete_and_restore_service",
                        title: TextOption.DeleteRestoreService[lang],
                        lastUpdated: "2022-12-24",
                    },
                ]
            },
            {
                id: "trucks",
                title: TextOption.Trucks[lang],
                lastUpdated: "2022-12-24",
            },
            {
                id: "drivers",
                title: TextOption.Drivers[lang],
                lastUpdated: "2022-12-24",
            },
        ]
    }, [lang])    


    return <ContentPage 
        homePage={<div>Help</div>}
        homePath='help'
        routes={helpRoutes}        
    />
}