// Vieolo UI
import Card from '@vieolo/vieolo-ui/dist/Card';
import Flex from '@vieolo/vieolo-ui/dist/Flex';
import Typography from '@vieolo/vieolo-ui/dist/Typography';

export default function TopicCard(props: {
    url: string,
    title: string,
    description: string
}) {
    return <a href={props.url}>
        <Card className='width--px-400 max-width--vw-90 height--px-150' emphasis='low'>
            <Flex direction='column' justifyContent='space-between' className='height--pc-100'>
                <Typography text={props.title} type='title-large' />
                <Typography type='paragraph-small' textAlign='right' text={props.description} />
            </Flex>
        </Card>
    </a>
}