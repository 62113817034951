// Redux
import { configureStore, combineReducers } from "@reduxjs/toolkit";

import AppReducer from "./appSlice";

export default configureStore({	
	reducer: {
		app: AppReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
});

export const rootReducer = combineReducers({
	app: AppReducer,
});

export type RootState = ReturnType<typeof rootReducer>;