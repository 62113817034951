// React
import React, { useState, useEffect, Suspense } from "react";
import { useSelector, useDispatch} from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "../app/store";
import { setInitialData } from "../app/appSlice";

// Vieolo UI
import Anchor from '@vieolo/vieolo-ui/dist/Anchor';
import { DropDownMenu } from "@vieolo/vieolo-ui";
import VieoloScaffold from '@vieolo/vieolo-ui/dist/VieoloScaffold';
import Button from '@vieolo/vieolo-ui/dist/Button';
import TreeList, { TreeListItem} from '@vieolo/vieolo-ui/dist/TreeList';
import Typography from '@vieolo/vieolo-ui/dist/Typography';
import Card from '@vieolo/vieolo-ui/dist/Card';
import Flex from '@vieolo/vieolo-ui/dist/Flex';
import Divider from '@vieolo/vieolo-ui/dist/Divider';
import Spinner from '@vieolo/vieolo-ui/dist/Spinner';
import Page from '@vieolo/vieolo-ui/dist/Page';
import GridContainer from '@vieolo/vieolo-ui/dist/GridContainer';
import Grid from '@vieolo/vieolo-ui/dist/Grid';
import Spacer from '@vieolo/vieolo-ui/dist/Spacer';

// Local UI
import ContentDirectionCard from "../components/content_direction_card";

// Installed Packages
import VDate from "@vieolo/date";
import { Browser } from '@vieolo/device-js'

// Utils and Logics
import { TextOption } from "../i18n";

type PathData = {
    /** The path of the previous page */
    previousID: string,
    /** The language aware title of the previous page */
    previousTitle: string,
    /** The path of the next page */
    nextID: string,
    /** The language aware title of the next page */
    nextTitle: string,
    /** The date of the last update to the page */
    lastUpdated: string,
    /** The language aware title of the current page */
    title: string,
    /** The paths and language aware titles of the parents */
    breadCrumb: { id: string, title: string }[]
}

export type ContentRoute = TreeListItem & {lastUpdated: string}


export default function ContentPage(props: {
    homePage: React.ReactNode,
    homePath: string,
    routes: ContentRoute[],
}) {
    let [path, setPath] = useState<string>('')
    
    // undfined -> The data is not set yet
    // null -> The target page is not found, 404 page is served
    // PathData -> The target page is found
    let [data, setData] = useState<PathData | undefined | null>(undefined);
    
    let lang = useSelector((state: RootState) => state.app.language)
    let dispatch = useDispatch()
    let history = useHistory();
    let location = useLocation();    

    useEffect(() => {
        let p = location.pathname.toLowerCase()
        if (p.startsWith(`/${props.homePath}`)) {
            if (p !== `/${props.homePath}` && p !== `/${props.homePath}/`) {
                let finalPath = p.replace(`/${props.homePath}/`, "")
                setPath(finalPath)

                function getAdjacentRoutes(): PathData {
                    let previousID = ""
                    let previousTitle = ""
                    let nextID = ""
                    let nextTitle = ""
                    let lastUpdated = ""
                    let title = ""

                    let sp = finalPath.split("/")
                    let last = ""
                    let parents: { [key: string]: string } = {}

                    for (let i = 0; i < sp.length - 1; i++) {
                        let th = sp[i];
                        if (i === 0) {
                            last = th;
                        } else {
                            last = `${last}/${th}`
                        }
                        parents[last] = ""
                    }

                    let flattened = props.routes.flatMap(z => z.children ? [z, ...z.children.flatMap(x => x.children ? [x, ...x.children.flatMap(q => q.children ? [q, ...q.children] : q)] : [x])] : [z])

                    for (let i = 0; i < flattened.length; i++) {
                        const hp = flattened[i];

                        if (hp.id === finalPath) {
                            lastUpdated = (hp as any).lastUpdated
                            title = hp.title;
                            if (flattened[i + 1]) {
                                nextID = flattened[i + 1].id
                                nextTitle = flattened[i + 1].title
                            }
                            if (flattened[i - 1]) {
                                previousID = flattened[i - 1].id
                                previousTitle = flattened[i - 1].title
                            }

                            break;
                        } else if (parents[hp.id] !== undefined) {
                            parents[hp.id] = hp.title
                        }
                    }
                    return {
                        breadCrumb: Object.keys(parents).map(z => {
                            return {
                                id: z,
                                title: parents[z]
                            }
                        }),
                        lastUpdated: lastUpdated,
                        title: title,
                        nextID: nextID,
                        nextTitle: nextTitle,
                        previousID: previousID,
                        previousTitle: previousTitle
                    }
                }

                let fd = getAdjacentRoutes();
                if (!fd.title.trim()) {
                    setData(null);
                } else {
                    setData(getAdjacentRoutes())
                }
            }
        }
    }, [location.pathname, props.routes, props.homePath])

    let isHomePage = [`/${props.homePath}`, `/${props.homePath}/`].includes(location.pathname.toLowerCase())

    // The data is being processed
    if (!isHomePage && data === undefined) return <Spinner />

    /** The MDX page to be rendered */
    let MDXPage: React.LazyExoticComponent<React.ComponentType<any>>;
    let HomePage: React.ReactNode | undefined;
        
    if (isHomePage) {
        HomePage = props.homePage;
        MDXPage = React.lazy(() => import(`./404/${lang.toLowerCase()}.mdx`)); // Just to quite the error
    } else if (data === null) {
        // The target page is not found
        MDXPage = React.lazy(() => import(`./404/${lang.toLowerCase()}.mdx`));
    } else {        
        MDXPage = React.lazy(() => import(`./${props.homePath}/${path}/${lang.toLowerCase()}.mdx`));
    }

    return <VieoloScaffold
        navbar={{
            logo: <img src='/static/logo.svg' alt='logo' height={40} />,
            logoRedirectURL: "/",
            title: "OneTruck.io",
            elevation: '1',
            rightComponents: [

                <DropDownMenu
                    key={"lang"}
                    buttonComponent={
                        <Button
                            emphasis='none-normal'
                            color='primary'
                            text={lang as any}
                            startIcon={<img src='/static/icons/translate.svg' alt='language' width={20} height={20} />}
                        />
                    }
                    items={[
                        { title: "English", value: "EN", icon: "EN" },
                        { title: "Nederlands", value: "NL", icon: "NL" },
                        { title: "Polski", value: "PL", icon: "PL" },
                    ]}
                    onItemSelect={async v => {
                        Browser.setCookie("lang", v, 1000 * 60 * 60 * 24 * 365)
                        dispatch(setInitialData(v as any))

                    }}
                />
            ]
        }}
        routes={[
            {
                path: "/",
                page: isHomePage 
                    ? <Page>{HomePage}</Page>
                    : <Page>
                    <GridContainer columnGap='two'>
                        <Grid xl={3} className='content-tree-list'>
                            <Spacer height='one' />
                            <TreeList
                                selectedID={path}
                                items={props.routes}
                                onItemSelect={(id, p) => {
                                    setPath(id)
                                    history.push(`/${props.homePath}/${id}`)
                                }}
                            />
                        </Grid>

                        <Grid xl={9} className='content-content-col'>
                            <div className="content-page-container">
                                <Flex>
                                    {
                                        (data ? data.breadCrumb : []).map(z => {
                                            return <p key={z.id}>
                                                <a
                                                    href={`/${props.homePath}/${z.id}`}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        history.push(`/${props.homePath}/${z.id}`)
                                                    }}
                                                >{z.title}</a> /
                                            </p>
                                        })
                                    }
                                </Flex>
                                <h1>{data ? data.title : "404"}</h1>
                                <Suspense fallback={<Spinner />}>
                                    <MDXPage lang={lang} />
                                </Suspense>
                            </div>

                            <Spacer height='two' />
                            {
                                data &&
                                <Flex justifyContent='end' columnGap='half' className='padding-right--two'>
                                    <Typography text={TextOption.LastUpdatedAt[lang]} />
                                    <Typography text={new VDate(data.lastUpdated || "2022-01-01").formatDate('dd/mm/yyyy')} fontWeight='bold' />
                                </Flex>
                            }
                            <Spacer height='one' />
                            <Divider direction='horizontal' thickness='1' colorType='light' length='pc-100' />

                            {
                                data &&
                                <Card>
                                    <Flex justifyContent='space-between' alignItems='center'>
                                        {
                                            data.previousID.trim()
                                                ? <ContentDirectionCard
                                                    direction='pre'
                                                    title={data.previousTitle}
                                                    url={`/${props.homePath}/${data.previousID}`}
                                                />
                                                : <div></div>
                                        }


                                        {
                                            data.nextID.trim()
                                                ? <ContentDirectionCard
                                                    direction='next'
                                                    title={data.nextTitle}
                                                    url={`/${props.homePath}/${data.nextID}`}
                                                />
                                                : <div></div>
                                        }
                                    </Flex>
                                </Card>
                            }
                            <Spacer height='two' />
                            <Spacer height='two' />
                            <Divider direction='horizontal' colorType='light' thickness='2' length='pc-100' />
                            <Card>
                                <Flex justifyContent='space-between'>
                                    <Flex columnGap='one' alignItems='center'>
                                        <img src='/static/logo.svg' alt='logo' height={20} />
                                        <div>
                                            <Typography text='OneTruck.io' fontWeight='bold' />
                                            <Typography text='All Rights Reserved - Vieolo OÜ' type='paragraph-small' />
                                        </div>
                                    </Flex>

                                    <Flex alignItems='center' columnGap='two'>
                                        <Anchor href="https://OneTruck.io" openInNewTab><Typography text='Your Account' type='paragraph-small' fontWeight='bold' /></Anchor>
                                        <Anchor href="/help/get_started"><Typography text='Help' type='paragraph-small' fontWeight='bold' /></Anchor>
                                        <Anchor href="/legal/terms"><Typography text='Terms of Service' type='paragraph-small' fontWeight='bold' /></Anchor>
                                        <Anchor href="/legal/privacy"><Typography text='Privacy' type='paragraph-small' fontWeight='bold' /></Anchor>
                                    </Flex>

                                </Flex>

                            </Card>
                        </Grid>
                    </GridContainer>
                </Page>
            }
        ]}
    />

}