import { SupportedLanguage } from "./i18n_base";

enum TextOptionProject {
    ReviewAndVerification,
    AddManually,
    AddARide,
    AddAStop,
    AttachAFile,
    ServiceRevenue,
    AddViaFile,
    AddViaTemplates,
    PlanUsingLocation,
    DeleteRestoreService,
    AssignmentPDF,
    HourlyRates,
    EditAService,
    LastUpdatedAt,
    AddYourAdminEmployees,
    AddYourTrucks,
    AddYourDrivers,
    AddYourTrailers,
    AddYourFirstService,
    NextSteps,
    Services,
    GetStarted,
    Drivers,
    Trucks,
    Notes,
    AddYourCustomers,
    Previous,
    ServiceStructure,
    AddAService,
    BillingDate,
    DrivingHours,
    CustomerDriverAndVehicles,
    WelcomeToContentPageTitle,
    WelcomeToContentPageDescription,
    LandingHelpCardDescription,
    LandingTermsCardDescription,
    LandingPrivacyCardDescription,
}


export const projectTexts: { [text in keyof typeof TextOptionProject]: { [language in SupportedLanguage]: string } } = {
    GetStarted: {
        [SupportedLanguage.EN]: "Get Started",
        [SupportedLanguage.NL]: "Begin",
        [SupportedLanguage.PL]: "Zaczynaj",
    },
    Drivers: {
        [SupportedLanguage.EN]: "Drivers",
        [SupportedLanguage.NL]: "Chauffeurs",
        [SupportedLanguage.PL]: "Kierowcy",
    },
    Trucks: {
        [SupportedLanguage.EN]: "Trucks",
        [SupportedLanguage.NL]: "Vrachtwagens",
        [SupportedLanguage.PL]: "Samochody ciężarowe",
    },
    Services: {
        [SupportedLanguage.EN]: "Services",
        [SupportedLanguage.NL]: "Services",
        [SupportedLanguage.PL]: "Usługi",
    },
    AddYourCustomers: {
        [SupportedLanguage.EN]: "Add your customers",
        [SupportedLanguage.NL]: "Voeg uw klanten toe",
        [SupportedLanguage.PL]: "Dodaj swoich klientów",
    },
    AddYourAdminEmployees: {
        [SupportedLanguage.EN]: "Add your admin employees",
        [SupportedLanguage.NL]: "Voeg uw admin-medewerkers toe",
        [SupportedLanguage.PL]: "Dodaj pracowników administracyjnych",
    },
    AddYourDrivers: {
        [SupportedLanguage.EN]: "Add your drivers",
        [SupportedLanguage.NL]: "Voeg uw chauffeurs toe",
        [SupportedLanguage.PL]: "Dodaj swoje sterowniki",
    },
    AddYourFirstService: {
        [SupportedLanguage.EN]: "Add your first service",
        [SupportedLanguage.NL]: "Voeg uw eerste dienst toe",
        [SupportedLanguage.PL]: "Dodaj swoją pierwszą usługę",
    },
    AddYourTrailers: {
        [SupportedLanguage.EN]: "Add your trailers",
        [SupportedLanguage.NL]: "Voeg uw aanhangwagens toe",
        [SupportedLanguage.PL]: "Dodaj swoje przyczepy",
    },
    AddYourTrucks: {
        [SupportedLanguage.EN]: "Add your trucks",
        [SupportedLanguage.NL]: "Voeg uw vrachtwagens toe",
        [SupportedLanguage.PL]: "Dodaj swoje ciężarówki",
    },
    NextSteps: {
        [SupportedLanguage.EN]: "Next Steps",
        [SupportedLanguage.NL]: "Volgende stappen",
        [SupportedLanguage.PL]: "Następne kroki",
    },
    Previous: {
        [SupportedLanguage.EN]: "Previous",
        [SupportedLanguage.NL]: "Vorig",
        [SupportedLanguage.PL]: "Poprzedni",
    },
    LastUpdatedAt: {
        [SupportedLanguage.EN]: "Last updated at",
        [SupportedLanguage.NL]: "Laatst bijgewerkt op",
        [SupportedLanguage.PL]: "Ostatnia aktualizacja",
    },
    AddAService: {
        [SupportedLanguage.EN]: "Add a service",
        [SupportedLanguage.NL]: "Voeg een service toe",
        [SupportedLanguage.PL]: "Dodaj usługę",
    },
    ServiceStructure: {
        [SupportedLanguage.EN]: "Service structure",
        [SupportedLanguage.NL]: "Service structuur",
        [SupportedLanguage.PL]: "Struktura serwisu",
    },
    EditAService: {
        [SupportedLanguage.EN]: "Edit a service",
        [SupportedLanguage.NL]: "Bewerk een service",
        [SupportedLanguage.PL]: "Edytuj usługę",
    },
    AssignmentPDF: {
        [SupportedLanguage.EN]: "Assignment PDF",
        [SupportedLanguage.NL]: "Opdracht pdf",
        [SupportedLanguage.PL]: "Zadanie PDF",
    },
    DeleteRestoreService: {
        [SupportedLanguage.EN]: "Delete and restore a service",
        [SupportedLanguage.NL]: "Een service verwijderen en herstellen",
        [SupportedLanguage.PL]: "Usuń i przywróć usługę",
    },
    AddManually: {
        [SupportedLanguage.EN]: "Add a service manually",
        [SupportedLanguage.NL]: "Voeg handmatig een service toe",
        [SupportedLanguage.PL]: "Dodaj usługę ręcznie",
    },
    AddViaFile: {
        [SupportedLanguage.EN]: "Add via a file",
        [SupportedLanguage.NL]: "Toevoegen via een bestand",
        [SupportedLanguage.PL]: "Dodaj przez plik",
    },
    AddViaTemplates: {
        [SupportedLanguage.EN]: "Add via templates",
        [SupportedLanguage.NL]: "Toevoegen via sjablonen",
        [SupportedLanguage.PL]: "Dodaj za pomocą szablonów",
    },
    PlanUsingLocation: {
        [SupportedLanguage.EN]: "Plan using the locations",
        [SupportedLanguage.NL]: "Plan met behulp van de locaties",
        [SupportedLanguage.PL]: "Zaplanuj wykorzystanie lokalizacji",
    },
    ReviewAndVerification: {
        [SupportedLanguage.EN]: "Review and verification",
        [SupportedLanguage.NL]: "Beoordeling en verificatie",
        [SupportedLanguage.PL]: "Przegląd i weryfikacja",
    },
    WelcomeToContentPageTitle: {
        [SupportedLanguage.EN]: "Welcome to the OneTruck content page",
        [SupportedLanguage.NL]: "Welkom op de inhoudspagina van OneTruck",
        [SupportedLanguage.PL]: "Witamy na stronie zawartości OneTruck",
    },
    WelcomeToContentPageDescription: {
        [SupportedLanguage.EN]: "Here you can find the information and guides needed to use the web platform and learn more about the terms and conditions of OneTruck.",
        [SupportedLanguage.NL]: "Hier vindt u de informatie en handleidingen die nodig zijn om het webplatform te gebruiken en leert u meer over de algemene voorwaarden van OneTruck.",
        [SupportedLanguage.PL]: "Tutaj możesz znaleźć informacje i przewodniki potrzebne do korzystania z platformy internetowej oraz dowiedzieć się więcej o warunkach OneTruck.",
    },
    LandingHelpCardDescription: {
        [SupportedLanguage.EN]: "Here you can find the guides and the necessary information to use OneTruck efficiently and effectively.",
        [SupportedLanguage.NL]: "Hier vindt u de handleidingen en de nodige informatie om OneTruck efficiënt en effectief te gebruiken.",
        [SupportedLanguage.PL]: "Tutaj znajdziesz przewodniki i informacje niezbędne do wydajnego i efektywnego korzystania z OneTruck.",
    },
    LandingTermsCardDescription: {
        [SupportedLanguage.EN]: "Here you can find the terms of service of the OneTruck web platform and services. This page outlines the rules governing your usage of OneTruck.",
        [SupportedLanguage.NL]: "Hier vindt u de servicevoorwaarden van het OneTruck-webplatform en -services. Op deze pagina staan de regels die van toepassing zijn op uw gebruik van OneTruck.",
        [SupportedLanguage.PL]: "Tutaj znajdziesz warunki korzystania z platformy internetowej i usług OneTruck. Ta strona przedstawia zasady regulujące korzystanie z OneTruck.",
    },
    LandingPrivacyCardDescription: {
        [SupportedLanguage.EN]: "Here you can find the privacy policy of the OneTruck web platform and services. This page outlines how we manage your data.",
        [SupportedLanguage.NL]: "Hier vindt u het privacybeleid van het OneTruck-webplatform en -services. Op deze pagina leest u hoe wij met uw gegevens omgaan.",
        [SupportedLanguage.PL]: "Tutaj znajdziesz politykę prywatności platformy internetowej i usług OneTruck. Ta strona przedstawia sposób, w jaki zarządzamy Twoimi danymi.",
    },
    BillingDate: {
        [SupportedLanguage.EN]: "Billing date",
        [SupportedLanguage.NL]: "Factuurdatum",
        [SupportedLanguage.PL]: "Termin spłaty",
    },
    CustomerDriverAndVehicles: {
        [SupportedLanguage.EN]: "Customer, driver, and vehicles",
        [SupportedLanguage.NL]: "Klant, chauffeur en voertuigen",
        [SupportedLanguage.PL]: "Klient, kierowca i pojazdy",
    },
    DrivingHours: {
        [SupportedLanguage.EN]: "Driving hours",
        [SupportedLanguage.NL]: "Rij-uren",
        [SupportedLanguage.PL]: "Godziny jazdy",
    },
    HourlyRates: {
        [SupportedLanguage.EN]: "Hourly rates",
        [SupportedLanguage.NL]: "Uurtarieven",
        [SupportedLanguage.PL]: "Stawki godzinowe",
    },
    ServiceRevenue: {
        [SupportedLanguage.EN]: "Service revenue",
        [SupportedLanguage.NL]: "Service-inkomsten",
        [SupportedLanguage.PL]: "Przychody z usług",
    },
    Notes: {
        [SupportedLanguage.EN]: "Notes",
        [SupportedLanguage.NL]: "Notities",
        [SupportedLanguage.PL]: "Notatki",
    },
    AddARide: {
        [SupportedLanguage.EN]: "Add a ride",
        [SupportedLanguage.NL]: "Voeg een rit toe",
        [SupportedLanguage.PL]: "Dodaj przejazd",
    },
    AddAStop: {
        [SupportedLanguage.EN]: "Add a stop",
        [SupportedLanguage.NL]: "Voeg een tussenstop toe",
        [SupportedLanguage.PL]: "Dodaj przystanek",
    },
    AttachAFile: {
        [SupportedLanguage.EN]: "Attach a file",
        [SupportedLanguage.NL]: "Voeg een bestand toe",
        [SupportedLanguage.PL]: "Dołączyć plik",
    },
}