import React from "react";
import { useHistory } from "react-router-dom";

export default function MDXAnchor(props: {link: string, children: React.ReactNode}) {
    let history = useHistory()

    let finalLink = props.link;
    if (finalLink[0] !== "/") finalLink = `/${finalLink}`

    return <a 
        href={props.link}
        onClick={e => {
            e.preventDefault();
            history.push(finalLink)
        }}
    >
        {props.children}
    </a>
}