// Vieolo UI
import Typography from '@vieolo/vieolo-ui/dist/Typography';
import Page from '@vieolo/vieolo-ui/dist/Page';
import GridContainer from '@vieolo/vieolo-ui/dist/GridContainer';
import Grid from '@vieolo/vieolo-ui/dist/Grid';
import Spacer from '@vieolo/vieolo-ui/dist/Spacer';
import TopicCard from './topic_card';
import { TextOption } from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';


export default function LandingHome(props: {}) {

    let lang = useSelector((state: RootState) => state.app.language)

    return <Page className='home-page'>

        <GridContainer>
            <Grid xl={6} sm={12} flex={{ className: 'min-height--vh-50 height--pc-100', direction: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div className='width--pc-60 max-width--vw-70'>
                    <Typography text={TextOption.WelcomeToContentPageTitle[lang]} type='title-large' />
                    <Spacer height='two' />
                    <Typography text={TextOption.WelcomeToContentPageDescription[lang]} />
                </div>
            </Grid>

            <Grid xl={6} sm={12} flex={{ direction: 'column', alignItems: 'center', rowGap: 'one', justifyContent: 'center', className: 'min-height--vh-80 height--pc-100' }}>

                <TopicCard
                    url='/help/get_started'
                    title='Help'
                    description={TextOption.LandingHelpCardDescription[lang]}
                />

                <TopicCard
                    url='/legal/terms'
                    title='Terms of Service'
                    description={TextOption.LandingTermsCardDescription[lang]}
                />

                <TopicCard
                    url='/legal/privacy'
                    title='Privacy Policy'
                    description={TextOption.LandingPrivacyCardDescription[lang]}
                />
                
            </Grid>
        </GridContainer>

    </Page>
}