// React
import { useMemo } from 'react';
import '../style/help.scss';
// import { useSelector } from 'react-redux';
// import { RootState } from '../app/store';
// import { setInitialData } from '../app/appSlice';
// import { useHistory, useLocation } from 'react-router-dom';

// Vieolo UI
// import Typography from '@vieolo/vieolo-ui/dist/Typography';
// import Spacer from '@vieolo/vieolo-ui/dist/Spacer';
// import Divider from '@vieolo/vieolo-ui/dist/Divider';
// import Flex from '@vieolo/vieolo-ui/dist/Flex';
// import Card from '@vieolo/vieolo-ui/dist/Card';
// import Spinner from '@vieolo/vieolo-ui/dist/Spinner';
// import VieoloScaffold from '@vieolo/vieolo-ui/dist/VieoloScaffold';
// import Page from '@vieolo/vieolo-ui/dist/Page';
// import Button from '@vieolo/vieolo-ui/dist/Button';
// import DropDownMenu from '@vieolo/vieolo-ui/dist/DropDownMenu';
// import GridContainer from '@vieolo/vieolo-ui/dist/GridContainer';
// import Grid from '@vieolo/vieolo-ui/dist/Grid';
// import TreeList, { TreeListItem } from '@vieolo/vieolo-ui/dist/TreeList';

// Utils and Logics
// import { TextOption } from '../i18n';

// Local UI
import ContentPage, { ContentRoute } from '../contents/content_page';



export default function LegalEntry(props: {}) {    
    
    // let lang = useSelector((state: RootState) => state.app.language)

    let legalRoutes: ContentRoute[] = useMemo(() => {
        return [
            {
                id: "terms",
                title: "Terms of Service",
                lastUpdated: "2023-01-23",                
            },
            {
                id: "privacy",
                title: "Privacy Policy",
                lastUpdated: "2023-01-23",
            },            
        ]
    }, [])    


    return <ContentPage 
        homePage={<div>Terms of service</div>}
        homePath='legal'
        routes={legalRoutes}        
    />
}