// React, Redux, Router
import React, { Suspense } from 'react';

// Local UI
import LandingPage from '../landing/landing_entry';
import HelpEntry from '../help/help_entry';
import LegalEntry from '../legal/legal_entry';



function App() {

	if (window.location.pathname.toLowerCase().startsWith("/blog")) {
		let BlogEntry = React.lazy(() => import('../blog/blog_entry'))	
		return <Suspense fallback={<span></span>}>
			<BlogEntry />
		</Suspense>
	} else if (window.location.pathname.toLowerCase().startsWith("/help")) {
		return <HelpEntry />
		// let HelpEntry = React.lazy(() => import('../help/help_entry'))	
		// return <Suspense fallback={<span></span>}>
		// 	<HelpEntry />
		// </Suspense>
	} else if (window.location.pathname.toLowerCase().startsWith("/legal")) {
		return <LegalEntry />
		// let HelpEntry = React.lazy(() => import('../help/help_entry'))	
		// return <Suspense fallback={<span></span>}>
		// 	<HelpEntry />
		// </Suspense>
	} else return <LandingPage />

}

export default App;
