import { createRoot } from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import store from "./app/store";
import VieoloApp from '@vieolo/vieolo-ui/dist/VieoloApp';

const root = createRoot(document.getElementById('root')!);
root.render(<VieoloApp store={store}>
  <App />
</VieoloApp>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
