// React
import React from 'react';

// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Logics and Utils
import { Browser } from '@vieolo/device-js'
import { SupportedLanguage } from '../i18n';

export type ConfirmationType = {
	content: string | React.ReactNode,
	onReject?: () => void,
	onAccept: () => void,
	rejectText?: string,
	acceptText?: string
}


const appSlice = createSlice({
	name: 'app',
	initialState: {
		// Authentication
		// user: undefined as User | null | undefined,
		// alert: { message: '', type: 'info' } as { message: string, type: 'info' | 'error' | 'success' },
		// confirmation: null as ConfirmationType,
		loading: false,
		language: (Browser.getSingleCookie("lang") as any || SupportedLanguage.EN) as SupportedLanguage,
		// confirmation: undefined as ConfirmationType | undefined,
		// fullScreenFile: null as { fileType: 'pdf', value: string | File, valueType: 'base64' | 'url' | 'file', fileName?: string } | null,
		// access: { archive: 1 },
	},
	reducers: {
		setInitialData(state, action: PayloadAction<SupportedLanguage>) {
			state.language = action.payload;
		},
		showLoading(state) {
			state.loading = true;
		},
		clearLoading(state) {
			state.loading = false;
		},
	}
})

export const {
	setInitialData,
	showLoading,
	clearLoading,
} = appSlice.actions;
export default appSlice.reducer;