// React
import '../style/landing.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../app/store';
import { setInitialData } from '../app/appSlice';

// Vieolo UI
import VieoloScaffold from '@vieolo/vieolo-ui/dist/VieoloScaffold';
import DropDownMenu from '@vieolo/vieolo-ui/dist/DropDownMenu';
import Button from '@vieolo/vieolo-ui/dist/Button';


// Local UI
import LandingHome from './home/landing_home';



export default function LandingPage(props: {}) {

    let lang = useSelector((state: RootState) => state.app.language)
    let dispatch = useDispatch()

    return <VieoloScaffold
        navbar={{
            logo: <img src='/static/logo.svg' alt='logo' height={40} />,
            title: "OneTruck.io",
            rightComponents: [
                <DropDownMenu
                    key={"lang"}
                    buttonComponent={
                        <Button
                            emphasis='none-normal'
                            color='primary'
                            text={lang as any}
                            startIcon={<img src='/static/icons/translate.svg' alt='language' width={20} height={20} />}
                        />
                    }
                    items={[
                        { title: "English", value: "EN", icon: "EN" },
                        { title: "Nederlands", value: "NL", icon: "NL" },
                        { title: "Polski", value: "PL", icon: "PL" },
                    ]}
                    onItemSelect={async v => {
                        
                        let { Browser } = await import('@vieolo/device-js')
                        Browser.setCookie("lang", v, 1000*60*60*24*365)
                        dispatch(setInitialData(v as any))

                    }}
                />

                // <a href='/blog'>
                //     <Button
                //         text='Blog'
                //         color='primary'
                //         emphasis='none-normal'
                //         height='small'
                //     />
                // </a>,
            ]
        }}
        routes={[
            { path: "/", page: <LandingHome /> },
        ]}
    />
}