// ** This file should not be manually changes **
//
// This file is managed by Oven CLI and any changes made to this file will be 
// overwritten after running any I18N commands via Oven CLI
// 
// To add any value specific to your project, change the `i18n_project.ts` file
// which won't be changed by Oven CLI after the initial generation.
// If you wish to override a translation of any of the base texts, you can redefine in
// `TextOptionProject` which has a higher priority.
//
// To Add a new text: 
// 1. First check if the desired text is available in the `baseTexts` or not.
// 2. If not present, or if you desire to override it, add a key to `TextOptionProject` enum
// 3. Add the translations as an object to `projectTexts`
// 4. The added text is automatically added to the list of texts with autocompletion. That's it

export enum SupportedLanguage {
    EN = "EN",
    NL = "NL",
    PL = "PL"
}

enum TextOptionBase {
    // Actions
    Activate,
    Add,
    AddCategory,
    AddCustomer,
    AddEmployee,
    AddEntry,
    AddFile,
    AddNote,
    AddTag,
    Analyze,
    Apply,
    Approve,
    Attach,
    Cancel,
    ChangePassword,
    CheckManually,
    Clear,
    ContactUs,
    Continue,
    Deactivate,
    Delete,
    Download,
    DownloadReport,
    Edit,
    Fill,
    GoBack,
    KnowMore,
    Login,
    Logout,
    MarkAsComplete,
    Reject,
    Reset,
    Save,
    Search,
    Send,
    SelectAWeek,
    SelectCategory,
    SignUp,
    Skip,
    Split,
    Swap,
    Track,
    Transfer,
    UploadFile,
    View,

    // Confirmation
    FileDeleteConfirm,
    ItemDeleteConfirm,
    ForgotYourPassword,
    NotAUserSignup,

    // Post Action    
    ChangeSaveError,
    DateOfExpiryValidationError,
    EmailSendError,
    EmailSent,
    EntryDeleteError,
    EntrySaveError,
    EntryWithNameExistError,
    FileDeleteError,
    FileDownloadError,
    FileUploadError,
    PleaseEnterAValid,
    ProfileUpdateSuccess,
    TemplateIsAdded,


    // Pages
    Home,
    Settings,
    Statistics,
    
    // Users / Team / Employees / Person / Party
    Address,
    CompanyDetails,
    CompanyPreferences,
    Employees,
    FirstName,
    GeneralPreferences,
    InternalName,
    LastName,
    Leave,
    Logo,
    Name,
    Nickname,
    OfficialName,
    Payer,
    PersonalPreferences,
    PhoneNumber,
    Profile,
    ProfilePicture,
    Receiver,

    // Math / Finance / Measurement
    AccountNumber,
    Amount,
    BankAddress,
    BankName,
    Billing,
    BranchCode,
    Costs,
    ExtraCost,
    Financial,
    Invoice,
    Meter,
    PaymentDate,
    Price,
    Pricing,
    Quantity,
    Rate,
    Revenue,
    Subtotal,
    SwiftCode,
    TollCost,
    Total,

    // Time
    Annual,
    Calendar,
    Daily,
    Date,
    DateOfExpiry,
    DateOfIssue,
    Day,
    Days,
    DueDate,
    Duration,
    End,
    GeneratedAt,
    History,
    Hours,
    Monthly,
    MonthlyReport,
    Pause,
    Period,
    PublicHolidays,
    Quarterly,
    RecentlyAdded,
    Schedule,
    Scheduled,
    ScheduledFor,
    ScheduledOn,
    Start,
    Time,
    Week,
    Weekday,
    Weekly,
    WorkingDays,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,

    // Direction / Position / Location
    City,
    Direction,
    External,
    Incoming,
    Internal,
    Outgoing,


    // State
    Active,
    Actual,
    Approved,
    Available,
    Done,
    Duplicate,
    Eligible,
    Empty,
    Efficient,
    HasAProblem,
    Inactive,
    Ineligible,
    Missing,
    More,
    New,
    Normal,
    NothingHere,
    Original,
    Optional,
    Paid,
    Received,
    Rejected,
    Sent,
    Unavailable,
    Unequal,
    Unsupported,
    WithReminder,
    WithoutReminder,

    // Sorting / Category / Filter
    Alphabetical,
    Category,
    Classification,
    Filters,
    SortByFrequency,
    SortByName,
    Subcategory,
    Type,

    // Languages
    English,
    Dutch,
    Polish,
    German,
    Italian,
    French,
    Turkish,

    // Countries
    Country,
    Countries,
    Austria, 
    Belgium, 
    Bulgaria, 
    Croatia, 
    Cyprus, 
    Czech, 
    Denmark, 
    Estonia, 
    Finland, 
    France, 
    Germany, 
    Greece, 
    Hungary, 
    Ireland, 
    Italy, 
    Latvia, 
    Lithuania, 
    Luxembourg, 
    Malta, 
    Netherlands, 
    Poland, 
    Portugal, 
    Romania, 
    Slovakia, 
    Slovenia, 
    Spain, 
    Sweden,

    // Others
    Activity,
    All,
    Assignment,
    Assignments,
    Communication,
    Context,
    Customer,
    Customers,
    Damage,
    Default,
    Description,
    Detail,
    Entries,
    Features,
    Fine,
    Group,
    Groups,
    Index,
    Langauge,
    Message,
    Model,
    Modifications,
    Next,
    Note,
    Overview,
    Privacy,
    Problem,
    Reference,
    Related,
    Reminder,
    Reservations,
    Resolution,
    Roster,
    Row,
    Services,
    Speed,
    Summary,
    Tags,
    Template,
    Templates,
    Title,
    UsingTemplate

}


export const baseTexts: { [text in keyof typeof TextOptionBase]: { [language in SupportedLanguage]: string } } = {
    Login: {
        [SupportedLanguage.EN]: "Login",
        [SupportedLanguage.NL]: "Inloggen",
        [SupportedLanguage.PL]: "Zaloguj sie",
    },
    Logout: {
        [SupportedLanguage.EN]: "Logout",
        [SupportedLanguage.NL]: "Uitloggen",
        [SupportedLanguage.PL]: "Wyloguj",
    },
    SignUp: {
        [SupportedLanguage.EN]: "Sign up",
        [SupportedLanguage.NL]: "Aanmelden",
        [SupportedLanguage.PL]: "Zapisz się",
    },
    Langauge: {
        [SupportedLanguage.EN]: "Language",
        [SupportedLanguage.NL]: "Taal",
        [SupportedLanguage.PL]: "Język",
    },
    ProfileUpdateSuccess: {
        [SupportedLanguage.EN]: "The Profile is successfully updated",
        [SupportedLanguage.NL]: "Het profiel is succesvol bijgewerkt",
        [SupportedLanguage.PL]: "Profil został pomyślnie zaktualizowany",
    },
    ChangeSaveError: {
        [SupportedLanguage.EN]: "There was problem saving the changes",
        [SupportedLanguage.NL]: "Er is een probleem opgetreden bij het opslaan van de wijzigingen",
        [SupportedLanguage.PL]: "Wystąpił problem z zapisaniem zmian",
    },
    Profile: {
        [SupportedLanguage.EN]: "Profile",
        [SupportedLanguage.NL]: "Profiel",
        [SupportedLanguage.PL]: "Profil",
    },
    Home: {
        [SupportedLanguage.EN]: "Home",
        [SupportedLanguage.NL]: "Home",
        [SupportedLanguage.PL]: "Dom",
    },
    Services: {
        [SupportedLanguage.EN]: "Services",
        [SupportedLanguage.NL]: "Services",
        [SupportedLanguage.PL]: "Usługi",
    },
    Reservations: {
        [SupportedLanguage.EN]: "Reservations",
        [SupportedLanguage.NL]: "Reserveringen",
        [SupportedLanguage.PL]: "Rezerwacje",
    },
    Settings: {
        [SupportedLanguage.EN]: "Settings",
        [SupportedLanguage.NL]: "Instellingen",
        [SupportedLanguage.PL]: "Ustawienia",
    },
    Costs: {
        [SupportedLanguage.EN]: "Costs",
        [SupportedLanguage.NL]: "Kosten",
        [SupportedLanguage.PL]: "Koszty",
    },
    Employees: {
        [SupportedLanguage.EN]: "Employees",
        [SupportedLanguage.NL]: "Medewerkers",
        [SupportedLanguage.PL]: "Pracowników",
    },
    Statistics: {
        [SupportedLanguage.EN]: "Statistics",
        [SupportedLanguage.NL]: "Statistieken",
        [SupportedLanguage.PL]: "Statystyka",
    },
    Customer: {
        [SupportedLanguage.EN]: "Customer",
        [SupportedLanguage.NL]: "Klant",
        [SupportedLanguage.PL]: "Klient",
    },
    Customers: {
        [SupportedLanguage.EN]: "Customers",
        [SupportedLanguage.NL]: "Klanten",
        [SupportedLanguage.PL]: "Klienci",
    },
    Search: {
        [SupportedLanguage.EN]: "Search",
        [SupportedLanguage.NL]: "Zoeken",
        [SupportedLanguage.PL]: "Szukaj",
    },
    Week: {
        [SupportedLanguage.EN]: "Week",
        [SupportedLanguage.NL]: "Week",
        [SupportedLanguage.PL]: "Tydzień",
    },
    Leave: {
        [SupportedLanguage.EN]: "Leave",
        [SupportedLanguage.NL]: "Verlof",
        [SupportedLanguage.PL]: "Opuszczać",
    },
    Edit: {
        [SupportedLanguage.EN]: "Edit",
        [SupportedLanguage.NL]: "Bewerking",
        [SupportedLanguage.PL]: "Edytować",
    },
    Add: {
        [SupportedLanguage.EN]: "Add",
        [SupportedLanguage.NL]: "Toevoegen",
        [SupportedLanguage.PL]: "Edytować",
    },
    AddEntry: {
        [SupportedLanguage.EN]: "Add an Entry",
        [SupportedLanguage.NL]: "Een vermelding toevoegen",
        [SupportedLanguage.PL]: "Dodaj wpis",
    },
    AddTag: {
        [SupportedLanguage.EN]: "Add a Tag",
        [SupportedLanguage.NL]: "Voeg een tag toe",
        [SupportedLanguage.PL]: "Dodać etykietę",
    },
    AddCategory: {
        [SupportedLanguage.EN]: "Add a Category",
        [SupportedLanguage.NL]: "Voeg een categorie toe",
        [SupportedLanguage.PL]: "Dodaj kategorię",
    },
    AddFile: {
        [SupportedLanguage.EN]: "Add a File",
        [SupportedLanguage.NL]: "Een bestand toevoegen",
        [SupportedLanguage.PL]: "Dodaj plik",
    },
    AddCustomer: {
        [SupportedLanguage.EN]: "Add a Customer",
        [SupportedLanguage.NL]: "Een klant toevoegen",
        [SupportedLanguage.PL]: "Dodaj klienta",
    },
    Delete: {
        [SupportedLanguage.EN]: "Delete",
        [SupportedLanguage.NL]: "Verwijderen",
        [SupportedLanguage.PL]: "Dodać",
    },
    ItemDeleteConfirm: {
        [SupportedLanguage.EN]: "Are you sure you want to delete this item?",
        [SupportedLanguage.NL]: "Weet je zeker dat je dit item wilt verwijderen?",
        [SupportedLanguage.PL]: "Czy na pewno chcesz usunąć ten element?",
    },
    FileDeleteConfirm: {
        [SupportedLanguage.EN]: "Are you sure you want to delete this file?",
        [SupportedLanguage.NL]: "Weet u zeker dat u dit bestand wilt verwijderen?",
        [SupportedLanguage.PL]: "Czy na pewno chcesz usunąć ten plik?",
    },
    Start: {
        [SupportedLanguage.EN]: "Start",
        [SupportedLanguage.NL]: "Begin",
        [SupportedLanguage.PL]: "Początek",
    },
    End: {
        [SupportedLanguage.EN]: "End",
        [SupportedLanguage.NL]: "Einde",
        [SupportedLanguage.PL]: "Kończyć się",
    },
    Note: {
        [SupportedLanguage.EN]: "Note",
        [SupportedLanguage.NL]: "Opmerking",
        [SupportedLanguage.PL]: "Notatka",
    },
    Cancel: {
        [SupportedLanguage.EN]: "Cancel",
        [SupportedLanguage.NL]: "Annuleren",
        [SupportedLanguage.PL]: "Anulować",
    },
    Save: {
        [SupportedLanguage.EN]: "Save",
        [SupportedLanguage.NL]: "Opslaan",
        [SupportedLanguage.PL]: "Zapisać",
    },
    EntrySaveError: {
        [SupportedLanguage.EN]: "There has been an error while saving this entry!",
        [SupportedLanguage.NL]: "Er is een fout opgetreden bij het opslaan van dit item!",
        [SupportedLanguage.PL]: "Wystąpił błąd podczas zapisywania tego wpisu!",
    },
    Day: {
        [SupportedLanguage.EN]: "Day",
        [SupportedLanguage.NL]: "Dagen",
        [SupportedLanguage.PL]: "Dni",
    },
    Days: {
        [SupportedLanguage.EN]: "Days",
        [SupportedLanguage.NL]: "Dag",
        [SupportedLanguage.PL]: "Dzień",
    },
    Summary: {
        [SupportedLanguage.EN]: "Summary",
        [SupportedLanguage.NL]: "Overzicht",
        [SupportedLanguage.PL]: "Streszczenie",
    },
    SelectAWeek: {
        [SupportedLanguage.EN]: "Select a Week",
        [SupportedLanguage.NL]: "Selecteer een week",
        [SupportedLanguage.PL]: "Wybierz tydzień",
    },
    Download: {
        [SupportedLanguage.EN]: "Download",
        [SupportedLanguage.NL]: "Downloaden",
        [SupportedLanguage.PL]: "Pobierać",
    },
    Incoming: {
        [SupportedLanguage.EN]: "Incoming",
        [SupportedLanguage.NL]: "Inkomend",
        [SupportedLanguage.PL]: "Przychodzące",
    },
    Outgoing: {
        [SupportedLanguage.EN]: "Outgoing",
        [SupportedLanguage.NL]: "Uitgaand",
        [SupportedLanguage.PL]: "Towarzyski",
    },
    Date: {
        [SupportedLanguage.EN]: "Date",
        [SupportedLanguage.NL]: "Datum",
        [SupportedLanguage.PL]: "Data",
    },
    DateOfIssue: {
        [SupportedLanguage.EN]: "Date of Issue",
        [SupportedLanguage.NL]: "Uitgavedatum",
        [SupportedLanguage.PL]: "Data wydania",
    },
    DateOfExpiry: {
        [SupportedLanguage.EN]: "Date of Expiry",
        [SupportedLanguage.NL]: "Vervaldatum",
        [SupportedLanguage.PL]: "Data ważności",
    },
    DateOfExpiryValidationError: {
        [SupportedLanguage.EN]: "Please enter a valid expiry date",
        [SupportedLanguage.NL]: "Voer een geldige vervaldatum in",
        [SupportedLanguage.PL]: "Wprowadź prawidłową datę ważności",
    },
    Reminder: {
        [SupportedLanguage.EN]: "Reminder",
        [SupportedLanguage.NL]: "Herinnering",
        [SupportedLanguage.PL]: "Przypomnienie",
    },
    FileDeleteError: {
        [SupportedLanguage.EN]: "There was a problem deleting this file!",
        [SupportedLanguage.NL]: "Er is een probleem opgetreden bij het verwijderen van dit bestand!",
        [SupportedLanguage.PL]: "Podczas usuwania tego pliku wystąpił problem!",
    },
    EntryDeleteError: {
        [SupportedLanguage.EN]: "There was a problem deleting this Entry!",
        [SupportedLanguage.NL]: "Er is een probleem opgetreden bij het verwijderen van dit item!",
        [SupportedLanguage.PL]: "Podczas usuwania tego wpisu wystąpił problem!",
    },
    EmailSendError: {
        [SupportedLanguage.EN]: "There was a problem sending this email!",
        [SupportedLanguage.NL]: "Er is een probleem opgetreden bij het verzenden van deze e-mail!",
        [SupportedLanguage.PL]: "Wystąpił problem z wysłaniem tego e-maila!",
    },

    EntryWithNameExistError: {
        [SupportedLanguage.EN]: "There is already an entry with this name!",
        [SupportedLanguage.NL]: "Er is al een item met deze naam!",
        [SupportedLanguage.PL]: "Wpis o tej nazwie już istnieje!",
    },
    Name: {
        [SupportedLanguage.EN]: "Name",
        [SupportedLanguage.NL]: "Naam",
        [SupportedLanguage.PL]: "Nazwa",
    },
    Price: {
        [SupportedLanguage.EN]: "Price",
        [SupportedLanguage.NL]: "Prijs",
        [SupportedLanguage.PL]: "Cena",
    },
    Internal: {
        [SupportedLanguage.EN]: "Internal",
        [SupportedLanguage.NL]: "Intern",
        [SupportedLanguage.PL]: "Wewnętrzny",
    },
    External: {
        [SupportedLanguage.EN]: "External",
        [SupportedLanguage.NL]: "Extern",
        [SupportedLanguage.PL]: "Zewnętrzny",
    },
    Classification: {
        [SupportedLanguage.EN]: "Classification",
        [SupportedLanguage.NL]: "Classificatie",
        [SupportedLanguage.PL]: "Klasyfikacja",
    },
    SortByFrequency: {
        [SupportedLanguage.EN]: "Sort by frequency of use",
        [SupportedLanguage.NL]: "Sorteren op gebruiksfrequentie",
        [SupportedLanguage.PL]: "Sortuj według częstotliwości użytkowania",
    },
    SortByName: {
        [SupportedLanguage.EN]: "Sort by Name",
        [SupportedLanguage.NL]: "Sorteren op naam",
        [SupportedLanguage.PL]: "Sortuj według nazwy",
    },
    Direction: {
        [SupportedLanguage.EN]: "Direction",
        [SupportedLanguage.NL]: "Richting",
        [SupportedLanguage.PL]: "Kierunek",
    },
    Tags: {
        [SupportedLanguage.EN]: "Tags",
        [SupportedLanguage.NL]: "Tags",
        [SupportedLanguage.PL]: "Tagi",
    },
    Templates: {
        [SupportedLanguage.EN]: "Templates",
        [SupportedLanguage.NL]: "Sjablonen",
        [SupportedLanguage.PL]: "Szablony",
    },
    Template: {
        [SupportedLanguage.EN]: 'Template',
        [SupportedLanguage.NL]: 'Sjabloon',
        [SupportedLanguage.PL]: 'Szablon',
    },
    TemplateIsAdded: {
        [SupportedLanguage.EN]: 'The template "xxx" is added',
        [SupportedLanguage.NL]: 'De sjabloon "xxx" is toegevoegd',
        [SupportedLanguage.PL]: 'Dodano szablon „xxx”',
    },
    UsingTemplate: {
        [SupportedLanguage.EN]: 'Using Template "xxx"',
        [SupportedLanguage.NL]: 'Sjabloon "xxx" gebruiken',
        [SupportedLanguage.PL]: 'Korzystanie z szablonu „xxx”',
    },
    Filters: {
        [SupportedLanguage.EN]: "Filters",
        [SupportedLanguage.NL]: "Filters",
        [SupportedLanguage.PL]: "Filtry",
    },
    Alphabetical: {
        [SupportedLanguage.EN]: "Alphabetical",
        [SupportedLanguage.NL]: "Alfabetisch",
        [SupportedLanguage.PL]: "Alfabetyczny",
    },
    RecentlyAdded: {
        [SupportedLanguage.EN]: "Recently Added",
        [SupportedLanguage.NL]: "Recent toegevoegd",
        [SupportedLanguage.PL]: "Niedawno dodany",
    },
    Calendar: {
        [SupportedLanguage.EN]: "Calendar",
        [SupportedLanguage.NL]: "Kalender",
        [SupportedLanguage.PL]: "Kalendarz",
    },
    FileUploadError: {
        [SupportedLanguage.EN]: "There was a problem uploading the file!",
        [SupportedLanguage.NL]: "Er is een probleem opgetreden bij het uploaden van het bestand!",
        [SupportedLanguage.PL]: "Wystąpił problem podczas przesyłania pliku!",
    },
    Sent: {
        [SupportedLanguage.EN]: "Sent",
        [SupportedLanguage.NL]: "Verstuurd",
        [SupportedLanguage.PL]: "Wysłano",
    },
    Received: {
        [SupportedLanguage.EN]: "Received",
        [SupportedLanguage.NL]: "Ontvangen",
        [SupportedLanguage.PL]: "Otrzymane",
    },
    NothingHere: {
        [SupportedLanguage.EN]: "Nothing Here",
        [SupportedLanguage.NL]: "Niets hier",
        [SupportedLanguage.PL]: "Nic tu nie ma",
    },
    Detail: {
        [SupportedLanguage.EN]: "Detail",
        [SupportedLanguage.NL]: "Detail",
        [SupportedLanguage.PL]: "Szczegół",
    },
    Schedule: {
        [SupportedLanguage.EN]: "Schedule",
        [SupportedLanguage.NL]: "Schema",
        [SupportedLanguage.PL]: "Harmonogram",
    },
    Title: {
        [SupportedLanguage.EN]: "Title",
        [SupportedLanguage.NL]: "Titel",
        [SupportedLanguage.PL]: "Tytuł",
    },
    SelectCategory: {
        [SupportedLanguage.EN]: "Select a Category",
        [SupportedLanguage.NL]: "Selecteer een categorie",
        [SupportedLanguage.PL]: "Wybierz kategorię",
    },
    Activate: {
        [SupportedLanguage.EN]: "Activate",
        [SupportedLanguage.NL]: "Activeren",
        [SupportedLanguage.PL]: "Aktywuj",
    },
    Deactivate: {
        [SupportedLanguage.EN]: "Deactivate",
        [SupportedLanguage.NL]: "Deactiveren",
        [SupportedLanguage.PL]: "Dezaktywować",
    },
    Active: {
        [SupportedLanguage.EN]: "Active",
        [SupportedLanguage.NL]: "Actief",
        [SupportedLanguage.PL]: "Aktywny",
    },
    Inactive: {
        [SupportedLanguage.EN]: "Inactive",
        [SupportedLanguage.NL]: "Inactief",
        [SupportedLanguage.PL]: "Nieaktywny",
    },
    Nickname: {
        [SupportedLanguage.EN]: "Nickname",
        [SupportedLanguage.NL]: "Bijnaam",
        [SupportedLanguage.PL]: "Przezwisko",
    },
    Model: {
        [SupportedLanguage.EN]: "Model",
        [SupportedLanguage.NL]: "Model",
        [SupportedLanguage.PL]: "Model",
    },
    Resolution: {
        [SupportedLanguage.EN]: "Resolution",
        [SupportedLanguage.NL]: "Oplossing",
        [SupportedLanguage.PL]: "Rezolucja",
    },
    Weekday: {
        [SupportedLanguage.EN]: "Weekday",
        [SupportedLanguage.NL]: "Weekdag",
        [SupportedLanguage.PL]: "Dzień powszedni",
    },
    Hours: {
        [SupportedLanguage.EN]: "Hours",
        [SupportedLanguage.NL]: "Uren",
        [SupportedLanguage.PL]: "Godziny",
    },
    EmailSent: {
        [SupportedLanguage.EN]: "Email Sent!",
        [SupportedLanguage.NL]: "Email verzonden!",
        [SupportedLanguage.PL]: "E-mail wysłany!",
    },
    Amount: {
        [SupportedLanguage.EN]: "Amount",
        [SupportedLanguage.NL]: "Bedrag",
        [SupportedLanguage.PL]: "Ilość",
    },
    Context: {
        [SupportedLanguage.EN]: "Context",
        [SupportedLanguage.NL]: "Context",
        [SupportedLanguage.PL]: "Kontekst",
    },
    Category: {
        [SupportedLanguage.EN]: "Category",
        [SupportedLanguage.NL]: "Categorie",
        [SupportedLanguage.PL]: "Kategoria",
    },
    Subcategory: {
        [SupportedLanguage.EN]: "Subcategory",
        [SupportedLanguage.NL]: "Subcategorie",
        [SupportedLanguage.PL]: "Podkategoria",
    },
    Related: {
        [SupportedLanguage.EN]: "Related",
        [SupportedLanguage.NL]: "Verwant",
        [SupportedLanguage.PL]: "Związane z",
    },
    Quantity: {
        [SupportedLanguage.EN]: "Quantity",
        [SupportedLanguage.NL]: "kwantiteit",
        [SupportedLanguage.PL]: "Ilość",
    },
    Meter: {
        [SupportedLanguage.EN]: "Meter",
        [SupportedLanguage.NL]: "Meting",
        [SupportedLanguage.PL]: "Pomiar",
    },
    Clear: {
        [SupportedLanguage.EN]: "Clear",
        [SupportedLanguage.NL]: "Wissen",
        [SupportedLanguage.PL]: "Oczyścić",
    },
    Apply: {
        [SupportedLanguage.EN]: "Apply",
        [SupportedLanguage.NL]: "Toepassen",
        [SupportedLanguage.PL]: "Aplikować",
    },
    Fine: {
        [SupportedLanguage.EN]: "Fine",
        [SupportedLanguage.NL]: "Straf",
        [SupportedLanguage.PL]: "Kara",
    },
    Damage: {
        [SupportedLanguage.EN]: "Damage",
        [SupportedLanguage.NL]: "Schade",
        [SupportedLanguage.PL]: "Szkoda",
    },
    View: {
        [SupportedLanguage.EN]: "View",
        [SupportedLanguage.NL]: "Bekijken",
        [SupportedLanguage.PL]: "Obejrzeć",
    },
    Revenue: {
        [SupportedLanguage.EN]: "Revenue",
        [SupportedLanguage.NL]: "Inkomsten",
        [SupportedLanguage.PL]: "Dochód",
    },
    Pause: {
        [SupportedLanguage.EN]: "Pause",
        [SupportedLanguage.NL]: "Pauze",
        [SupportedLanguage.PL]: "Pauza",
    },
    Total: {
        [SupportedLanguage.EN]: "Total",
        [SupportedLanguage.NL]: "Totaal",
        [SupportedLanguage.PL]: "Całkowity",
    },
    Subtotal: {
        [SupportedLanguage.EN]: "Subtotal",
        [SupportedLanguage.NL]: "Subtotaal",
        [SupportedLanguage.PL]: "Suma częściowa",
    },
    Description: {
        [SupportedLanguage.EN]: "Description",
        [SupportedLanguage.NL]: "Beschrijving",
        [SupportedLanguage.PL]: "Opis",
    },
    PaymentDate: {
        [SupportedLanguage.EN]: "Payment Date",
        [SupportedLanguage.NL]: "Betaaldatum",
        [SupportedLanguage.PL]: "Termin płatności",
    },
    UploadFile: {
        [SupportedLanguage.EN]: "Upload File",
        [SupportedLanguage.NL]: "Upload bestand",
        [SupportedLanguage.PL]: "Przesyłanie pliku",
    },
    Invoice: {
        [SupportedLanguage.EN]: "Invoice",
        [SupportedLanguage.NL]: "Factuur",
        [SupportedLanguage.PL]: "Faktura",
    },
    Communication: {
        [SupportedLanguage.EN]: "Communication",
        [SupportedLanguage.NL]: "Communicatie",
        [SupportedLanguage.PL]: "Komunikacja",
    },
    DueDate: {
        [SupportedLanguage.EN]: "Due Date",
        [SupportedLanguage.NL]: "Opleveringsdatum",
        [SupportedLanguage.PL]: "Termin płatności",
    },
    ScheduledOn: {
        [SupportedLanguage.EN]: "Scheduled on",
        [SupportedLanguage.NL]: "Gepland op",
        [SupportedLanguage.PL]: "Zaplanowano na",
    },
    AddNote: {
        [SupportedLanguage.EN]: "Add a Note",
        [SupportedLanguage.NL]: "Voeg een notitie toe",
        [SupportedLanguage.PL]: "Dodaj notatkę",
    },
    Problem: {
        [SupportedLanguage.EN]: "Problem",
        [SupportedLanguage.NL]: "Probleem",
        [SupportedLanguage.PL]: "Problem",
    },
    Reject: {
        [SupportedLanguage.EN]: "Reject",
        [SupportedLanguage.NL]: "Afwijzen",
        [SupportedLanguage.PL]: "Odrzucić",
    },
    Rejected: {
        [SupportedLanguage.EN]: "Rejected",
        [SupportedLanguage.NL]: "Afgewezen",
        [SupportedLanguage.PL]: "Odrzucony",
    },
    Approve: {
        [SupportedLanguage.EN]: "Approve",
        [SupportedLanguage.NL]: "Goedkeuren",
        [SupportedLanguage.PL]: "Zatwierdzić",
    },
    Approved: {
        [SupportedLanguage.EN]: "Approved",
        [SupportedLanguage.NL]: "Goedgekeurd",
        [SupportedLanguage.PL]: "Zatwierdzony",
    },
    MarkAsComplete: {
        [SupportedLanguage.EN]: "Mark as complete",
        [SupportedLanguage.NL]: "Markeren als voltooid",
        [SupportedLanguage.PL]: "Oznacz jako ukończone",
    },
    HasAProblem: {
        [SupportedLanguage.EN]: "Has a problem",
        [SupportedLanguage.NL]: "Heeft een probleem",
        [SupportedLanguage.PL]: "Ma problem",
    },
    Scheduled: {
        [SupportedLanguage.EN]: "Scheduled",
        [SupportedLanguage.NL]: "Gepland",
        [SupportedLanguage.PL]: "Zaplanowany",
    },
    Done: {
        [SupportedLanguage.EN]: "Done",
        [SupportedLanguage.NL]: "Gedaan",
        [SupportedLanguage.PL]: "Gotowe",
    },
    Privacy: {
        [SupportedLanguage.EN]: "Privacy",
        [SupportedLanguage.NL]: "Privacy",
        [SupportedLanguage.PL]: "Prywatność",
    },
    Eligible: {
        [SupportedLanguage.EN]: "Eligible",
        [SupportedLanguage.NL]: "In aanmerking komende",
        [SupportedLanguage.PL]: "Wybieralny",
    },
    Ineligible: {
        [SupportedLanguage.EN]: "Ineligible",
        [SupportedLanguage.NL]: "komt niet in aanmerking",
        [SupportedLanguage.PL]: "Którego nie można wybrać",
    },
    WithReminder: {
        [SupportedLanguage.EN]: "With Reminder",
        [SupportedLanguage.NL]: "Met herinnering",
        [SupportedLanguage.PL]: "Z przypomnieniem",
    },
    WithoutReminder: {
        [SupportedLanguage.EN]: "Without Reminder",
        [SupportedLanguage.NL]: "Zonder Herinnering",
        [SupportedLanguage.PL]: "Bez przypomnienia",
    },
    Unsupported: {
        [SupportedLanguage.EN]: "Unsupported",
        [SupportedLanguage.NL]: "Niet ondersteund",
        [SupportedLanguage.PL]: "Nieobsługiwane",
    },
    AccountNumber: {
        [SupportedLanguage.EN]: "Account Number",
        [SupportedLanguage.NL]: "Rekeningnummer",
        [SupportedLanguage.PL]: "Numer konta",
    },
    BankName: {
        [SupportedLanguage.EN]: "Bank Name",
        [SupportedLanguage.NL]: "Banknaam",
        [SupportedLanguage.PL]: "Nazwa banku",
    },
    BranchCode: {
        [SupportedLanguage.EN]: "Branch Code",
        [SupportedLanguage.NL]: "Filiaalcode",
        [SupportedLanguage.PL]: "Kod oddziału",
    },
    SwiftCode: {
        [SupportedLanguage.EN]: "BIC",
        [SupportedLanguage.NL]: "BIC",
        [SupportedLanguage.PL]: "BIC",
    },
    BankAddress: {
        [SupportedLanguage.EN]: "Bank Address",
        [SupportedLanguage.NL]: "Bank adres",
        [SupportedLanguage.PL]: "Numer konta",
    },
    Default: {
        [SupportedLanguage.EN]: "Default Option",
        [SupportedLanguage.NL]: "Standaard optie",
        [SupportedLanguage.PL]: "Domyślna opcja",
    },
    ScheduledFor: {
        [SupportedLanguage.EN]: "Scheduled For xxx",
        [SupportedLanguage.NL]: "Gepland voor xxx",
        [SupportedLanguage.PL]: "Zaplanowane na xxx",
    },
    Receiver: {
        [SupportedLanguage.EN]: "Receiver",
        [SupportedLanguage.NL]: "Ontvanger",
        [SupportedLanguage.PL]: "Odbiorca",
    },
    GeneratedAt: {
        [SupportedLanguage.EN]: "Generated at xxx",
        [SupportedLanguage.NL]: "Gegenereerd op xxx",
        [SupportedLanguage.PL]: "Wygenerowano o xxx",
    },
    Payer: {
        [SupportedLanguage.EN]: "Payer",
        [SupportedLanguage.NL]: "Betaler",
        [SupportedLanguage.PL]: "Płatnik",
    },
    Entries: {
        [SupportedLanguage.EN]: "Entries",
        [SupportedLanguage.NL]: "Inzendingen",
        [SupportedLanguage.PL]: "Wpisy",
    },
    Transfer: {
        [SupportedLanguage.EN]: "Transfer",
        [SupportedLanguage.NL]: "Overdracht",
        [SupportedLanguage.PL]: "Przenosić",
    },
    Split: {
        [SupportedLanguage.EN]: "Split",
        [SupportedLanguage.NL]: "Splitsen",
        [SupportedLanguage.PL]: "Podział",
    },
    Empty: {
        [SupportedLanguage.EN]: "Empty",
        [SupportedLanguage.NL]: "Leeg",
        [SupportedLanguage.PL]: "Pusty",
    },
    Track: {
        [SupportedLanguage.EN]: "Track",
        [SupportedLanguage.NL]: "Spoor",
        [SupportedLanguage.PL]: "Ścieżka",
    },
    Daily: {
        [SupportedLanguage.EN]: "Daily",
        [SupportedLanguage.NL]: "Dagelijks",
        [SupportedLanguage.PL]: "Codzienny",
    },
    Weekly: {
        [SupportedLanguage.EN]: "Weekly",
        [SupportedLanguage.NL]: "Wekelijks",
        [SupportedLanguage.PL]: "Co tydzień",
    },
    Monthly: {
        [SupportedLanguage.EN]: "Monthly",
        [SupportedLanguage.NL]: "Maandelijks",
        [SupportedLanguage.PL]: "Miesięczny",
    },
    Quarterly: {
        [SupportedLanguage.EN]: "Quarterly",
        [SupportedLanguage.NL]: "Per kwartaal",
        [SupportedLanguage.PL]: "Kwartalny",
    },
    Annual: {
        [SupportedLanguage.EN]: "Annual",
        [SupportedLanguage.NL]: "Jaarlijks",
        [SupportedLanguage.PL]: "Coroczny",
    },
    Period: {
        [SupportedLanguage.EN]: "Annual",
        [SupportedLanguage.NL]: "Punt uit",
        [SupportedLanguage.PL]: "Okres",
    },
    Financial: {
        [SupportedLanguage.EN]: "Financial",
        [SupportedLanguage.NL]: "Financieel",
        [SupportedLanguage.PL]: "Budżetowy",
    },
    InternalName: {
        [SupportedLanguage.EN]: "Internal Name",
        [SupportedLanguage.NL]: "Interne Naam",
        [SupportedLanguage.PL]: "Nazwa wewnętrzna",
    },
    OfficialName: {
        [SupportedLanguage.EN]: "Official Name",
        [SupportedLanguage.NL]: "Officiele Naam",
        [SupportedLanguage.PL]: "Oficjalne imię",
    },
    Address: {
        [SupportedLanguage.EN]: "Address",
        [SupportedLanguage.NL]: "Adres",
        [SupportedLanguage.PL]: "Adres",
    },
    Logo: {
        [SupportedLanguage.EN]: "Logo",
        [SupportedLanguage.NL]: "Logo",
        [SupportedLanguage.PL]: "Logo",
    },
    PhoneNumber: {
        [SupportedLanguage.EN]: "Phone Number",
        [SupportedLanguage.NL]: "Telefoonnummer",
        [SupportedLanguage.PL]: "Numer telefonu",
    },
    PersonalPreferences: {
        [SupportedLanguage.EN]: "Personal Preference",
        [SupportedLanguage.NL]: "Persoonlijke voorkeur",
        [SupportedLanguage.PL]: "Osobiste preferencje",
    },
    GeneralPreferences: {
        [SupportedLanguage.EN]: "General Preference",
        [SupportedLanguage.NL]: "Algemene voorkeuren",
        [SupportedLanguage.PL]: "ogólne preferencje",
    },
    ChangePassword: {
        [SupportedLanguage.EN]: "Change Password",
        [SupportedLanguage.NL]: "Verander wachtwoord",
        [SupportedLanguage.PL]: "Zmień hasło",
    },
    MonthlyReport: {
        [SupportedLanguage.EN]: "Monthly Report",
        [SupportedLanguage.NL]: "Maandelijks rapport",
        [SupportedLanguage.PL]: "Raport miesięczny",
    },
    CompanyPreferences: {
        [SupportedLanguage.EN]: "Company Preferences",
        [SupportedLanguage.NL]: "Bedrijfsvoorkeuren",
        [SupportedLanguage.PL]: "Preferencje firmy",
    },
    PublicHolidays: {
        [SupportedLanguage.EN]: "Public Holidays",
        [SupportedLanguage.NL]: "Wettelijke Feestdag",
        [SupportedLanguage.PL]: "Święta",
    },
    History: {
        [SupportedLanguage.EN]: "History",
        [SupportedLanguage.NL]: "Geschiedenis",
        [SupportedLanguage.PL]: "Historia",
    },
    Activity: {
        [SupportedLanguage.EN]: "Activity",
        [SupportedLanguage.NL]: "Werkzaamheid",
        [SupportedLanguage.PL]: "Działalność",
    },
    Continue: {
        [SupportedLanguage.EN]: 'Continue',
        [SupportedLanguage.NL]: "Doorgaan",
        [SupportedLanguage.PL]: 'Kontyntynuj',
    },
    KnowMore: {
        [SupportedLanguage.EN]: 'Know More',
        [SupportedLanguage.NL]: "Meer weten",
        [SupportedLanguage.PL]: 'Wiedzieć więcej',
    },
    CheckManually: {
        [SupportedLanguage.EN]: 'Check Manually',
        [SupportedLanguage.NL]: "Handmatig controleren",
        [SupportedLanguage.PL]: 'Sprawdź ręcznie',
    },
    PleaseEnterAValid: {
        [SupportedLanguage.EN]: 'Please enter a valid "xxx"',
        [SupportedLanguage.NL]: 'Voer een geldige "xxx" in',
        [SupportedLanguage.PL]: 'Proszę wpisać poprawny „xxx”',
    },
    Original: {
        [SupportedLanguage.EN]: 'Original',
        [SupportedLanguage.NL]: 'Origineel',
        [SupportedLanguage.PL]: 'Oryginał',
    },
    New: {
        [SupportedLanguage.EN]: 'New',
        [SupportedLanguage.NL]: 'Nieuw',
        [SupportedLanguage.PL]: 'Nowy',
    },
    Overview: {
        [SupportedLanguage.EN]: 'Overview',
        [SupportedLanguage.NL]: 'Overzicht',
        [SupportedLanguage.PL]: 'Przegląd',
    },
    Billing: {
        [SupportedLanguage.EN]: 'Billing',
        [SupportedLanguage.NL]: 'Facturering',
        [SupportedLanguage.PL]: 'Dane do faktury',
    },
    TollCost: {
        [SupportedLanguage.EN]: 'Toll Cost',
        [SupportedLanguage.NL]: 'Tolkosten',
        [SupportedLanguage.PL]: 'Koszt opłaty',
    },
    ExtraCost: {
        [SupportedLanguage.EN]: 'Extra Cost',
        [SupportedLanguage.NL]: 'Extra kosten',
        [SupportedLanguage.PL]: 'Dodatkowy koszt',
    },
    Fill: {
        [SupportedLanguage.EN]: 'Fill',
        [SupportedLanguage.NL]: 'Vullen',
        [SupportedLanguage.PL]: 'Wypełnić',
    },
    WorkingDays: {
        [SupportedLanguage.EN]: 'Working Days',
        [SupportedLanguage.NL]: 'Werkdagen',
        [SupportedLanguage.PL]: 'Dni robocze',
    },
    Analyze: {
        [SupportedLanguage.EN]: 'Analyze',
        [SupportedLanguage.NL]: 'Analyseren',
        [SupportedLanguage.PL]: 'Analizować',
    },
    Attach: {
        [SupportedLanguage.EN]: 'Attach',
        [SupportedLanguage.NL]: 'Bijvoegen',
        [SupportedLanguage.PL]: 'Przytwierdzać',
    },
    FileDownloadError: {
        [SupportedLanguage.EN]: 'There was an error downloading the file(s)',
        [SupportedLanguage.NL]: 'Er is een fout opgetreden bij het downloaden van de bestanden',
        [SupportedLanguage.PL]: 'Wystąpił błąd podczas pobierania plików',
    },
    Skip: {
        [SupportedLanguage.EN]: 'Skip',
        [SupportedLanguage.NL]: 'Overslaan',
        [SupportedLanguage.PL]: 'Pomijać',
    },
    Paid: {
        [SupportedLanguage.EN]: 'Paid',
        [SupportedLanguage.NL]: 'Betaald',
        [SupportedLanguage.PL]: 'Płatny',
    },
    Normal: {
        [SupportedLanguage.EN]: 'Normal',
        [SupportedLanguage.NL]: 'Normaal',
        [SupportedLanguage.PL]: 'Normalna',
    },
    Index: {
        [SupportedLanguage.EN]: 'Index',
        [SupportedLanguage.NL]: 'Index',
        [SupportedLanguage.PL]: 'Indeks',
    },
    Reference: {
        [SupportedLanguage.EN]: 'Reference',
        [SupportedLanguage.NL]: 'Referentie',
        [SupportedLanguage.PL]: 'Odniesienie',
    },
    DownloadReport: {
        [SupportedLanguage.EN]: 'Download Report',
        [SupportedLanguage.NL]: 'Rapport Downloaden',
        [SupportedLanguage.PL]: 'Pobierz raport',
    },
    Assignment: {
        [SupportedLanguage.EN]: 'Assignment',
        [SupportedLanguage.NL]: 'Opdracht',
        [SupportedLanguage.PL]: 'Zadanie',
    },
    Assignments: {
        [SupportedLanguage.EN]: 'Assignments',
        [SupportedLanguage.NL]: 'Opdrachten',
        [SupportedLanguage.PL]: 'Zadania',
    },
    Available: {
        [SupportedLanguage.EN]: 'Available',
        [SupportedLanguage.NL]: 'Beschikbaar',
        [SupportedLanguage.PL]: 'Do dyspozycji',
    },
    Unavailable: {
        [SupportedLanguage.EN]: 'Unavailable',
        [SupportedLanguage.NL]: 'Niet beschikbaar',
        [SupportedLanguage.PL]: 'Niedostępne',
    },
    CompanyDetails: {
        [SupportedLanguage.EN]: 'Company Details',
        [SupportedLanguage.NL]: 'Bedrijfsgegevens',
        [SupportedLanguage.PL]: 'Szczegóły firmy',
    },
    Time: {
        [SupportedLanguage.EN]: 'Time',
        [SupportedLanguage.NL]: 'Tijd',
        [SupportedLanguage.PL]: 'Czas',
    },
    Type: {
        [SupportedLanguage.EN]: 'Type',
        [SupportedLanguage.NL]: 'Type',
        [SupportedLanguage.PL]: 'Rodzaj',
    },
    Speed: {
        [SupportedLanguage.EN]: 'Speed',
        [SupportedLanguage.NL]: 'Snelheid',
        [SupportedLanguage.PL]: 'Prędkość',
    },
    City: {
        [SupportedLanguage.EN]: 'City',
        [SupportedLanguage.NL]: 'Stad',
        [SupportedLanguage.PL]: 'Miasto',
    },
    Actual: {
        [SupportedLanguage.EN]: 'Actual',
        [SupportedLanguage.NL]: 'Werkelijke',
        [SupportedLanguage.PL]: 'Rzeczywisty',
    },
    Row: {
        [SupportedLanguage.EN]: 'Row',
        [SupportedLanguage.NL]: 'Rij',
        [SupportedLanguage.PL]: 'Wiersz',
    },
    Duration: {
        [SupportedLanguage.EN]: 'Duration',
        [SupportedLanguage.NL]: 'Duur',
        [SupportedLanguage.PL]: 'Czas trwania',
    },
    Modifications: {
        [SupportedLanguage.EN]: 'Modifications',
        [SupportedLanguage.NL]: 'Wijzigingen',
        [SupportedLanguage.PL]: 'Modyfikacje',
    },
    Swap: {
        [SupportedLanguage.EN]: 'Swap',
        [SupportedLanguage.NL]: 'Ruil',
        [SupportedLanguage.PL]: 'Zamieniać',
    },
    Missing: {
        [SupportedLanguage.EN]: 'Missing',
        [SupportedLanguage.NL]: 'Missend',
        [SupportedLanguage.PL]: 'Zaginiony',
    },
    Duplicate: {
        [SupportedLanguage.EN]: 'Duplicate',
        [SupportedLanguage.NL]: 'Duplicaat',
        [SupportedLanguage.PL]: 'Duplikować',
    },
    All: {
        [SupportedLanguage.EN]: 'All',
        [SupportedLanguage.NL]: 'Allemaal',
        [SupportedLanguage.PL]: 'Wszystko',
    },
    Unequal: {
        [SupportedLanguage.EN]: 'Unequal',
        [SupportedLanguage.NL]: 'Ongelijke',
        [SupportedLanguage.PL]: 'Nierówny',
    },
    ContactUs: {
        [SupportedLanguage.EN]: 'Contact us',
        [SupportedLanguage.NL]: 'Contact ons',
        [SupportedLanguage.PL]: 'Skontaktuj nami',
    },
    Pricing: {
        [SupportedLanguage.EN]: 'Pricing',
        [SupportedLanguage.NL]: 'Prijzen',
        [SupportedLanguage.PL]: 'Cennik',
    },
    Features: {
        [SupportedLanguage.EN]: 'Features',
        [SupportedLanguage.NL]: 'Functies',
        [SupportedLanguage.PL]: 'Cechy',
    },
    Reset: {
        [SupportedLanguage.EN]: 'Reset',
        [SupportedLanguage.NL]: 'Resetten',
        [SupportedLanguage.PL]: 'Resetowanie',
    },
    ForgotYourPassword: {
        [SupportedLanguage.EN]: 'Forgot your password?',
        [SupportedLanguage.NL]: 'Je wachtwoord vergeten?',
        [SupportedLanguage.PL]: 'Zapomniałeś hasła?',
    },
    NotAUserSignup: {
        [SupportedLanguage.EN]: 'Not a user? Sign up',
        [SupportedLanguage.NL]: 'Geen gebruiker? Aanmelden',
        [SupportedLanguage.PL]: 'Nie jesteś użytkownikiem? Zapisz się',
    },
    More: {
        [SupportedLanguage.EN]: 'More',
        [SupportedLanguage.NL]: 'Meer',
        [SupportedLanguage.PL]: 'Więcej',
    },
    Efficient: {
        [SupportedLanguage.EN]: 'Efficient',
        [SupportedLanguage.NL]: 'Efficiënt',
        [SupportedLanguage.PL]: 'Wydajny',
    },
    Message: {
        [SupportedLanguage.EN]: 'Message',
        [SupportedLanguage.NL]: 'Bericht',
        [SupportedLanguage.PL]: 'Wiadomość',
    },
    Send: {
        [SupportedLanguage.EN]: 'Send',
        [SupportedLanguage.NL]: 'Versturen',
        [SupportedLanguage.PL]: 'Wysłać',
    },
    Rate: {
        [SupportedLanguage.EN]: "Rate",
        [SupportedLanguage.NL]: 'Tarief',
        [SupportedLanguage.PL]: "Wskaźnik",
    },
    Monday: {
        [SupportedLanguage.EN]: 'Monday',
        [SupportedLanguage.NL]: 'Maandag',
        [SupportedLanguage.PL]: "Poniedziałek",
    },
    Tuesday: {
        [SupportedLanguage.EN]: "Tuesday",
        [SupportedLanguage.NL]: 'Dinsdag',
        [SupportedLanguage.PL]: "Wtorek",
    },
    Wednesday: {
        [SupportedLanguage.EN]: "Wednesday",
        [SupportedLanguage.NL]: 'Woensdag',
        [SupportedLanguage.PL]: "Środa",
    },
    Thursday: {
        [SupportedLanguage.EN]: "Thursday",
        [SupportedLanguage.NL]: 'Donderdag',
        [SupportedLanguage.PL]: "Czwartek",
    },
    Friday: {
        [SupportedLanguage.EN]: "Friday",
        [SupportedLanguage.NL]: 'Vrijdag',
        [SupportedLanguage.PL]: "Piątek",
    },
    Saturday: {
        [SupportedLanguage.EN]: "Saturday",
        [SupportedLanguage.NL]: 'Zaterdag',
        [SupportedLanguage.PL]: "Sobota",
    },
    Sunday: {
        [SupportedLanguage.EN]: "Sunday",
        [SupportedLanguage.NL]: 'Zondag',
        [SupportedLanguage.PL]: "Niedziela",
    },
    GoBack: {
        [SupportedLanguage.EN]: "Go Back",
        [SupportedLanguage.NL]: 'Ga terug',
        [SupportedLanguage.PL]: "Wróć",
    },
    Next: {
        [SupportedLanguage.EN]: "Next",
        [SupportedLanguage.NL]: 'Volgende',
        [SupportedLanguage.PL]: "Następny",
    },
    English: {
        [SupportedLanguage.EN]: "English",
        [SupportedLanguage.NL]: 'Engels',
        [SupportedLanguage.PL]: "Język Angielski",
    },
    Dutch: {
        [SupportedLanguage.EN]: "Dutch",
        [SupportedLanguage.NL]: 'Nederlands',
        [SupportedLanguage.PL]: "Holenderski",
    },
    Polish: {
        [SupportedLanguage.EN]: "Polish",
        [SupportedLanguage.NL]: 'Pools',
        [SupportedLanguage.PL]: "Polski",
    },
    French: {
        [SupportedLanguage.EN]: "French",
        [SupportedLanguage.NL]: 'Frans',
        [SupportedLanguage.PL]: "Francuski",
    },
    German: {
        [SupportedLanguage.EN]: "German",
        [SupportedLanguage.NL]: 'Duits',
        [SupportedLanguage.PL]: "Niemiecki",
    },
    Italian: {
        [SupportedLanguage.EN]: "Italian",
        [SupportedLanguage.NL]: 'Italiaans',
        [SupportedLanguage.PL]: "Włoski",
    },
    Turkish: {
        [SupportedLanguage.EN]: "Turkish",
        [SupportedLanguage.NL]: 'Turks',
        [SupportedLanguage.PL]: "Turecki",
    },
    FirstName: {
        [SupportedLanguage.EN]: "First Name",
        [SupportedLanguage.NL]: 'Voornaam',
        [SupportedLanguage.PL]: "Imię",
    },
    LastName: {
        [SupportedLanguage.EN]: "Last Name",
        [SupportedLanguage.NL]: 'Achternaam',
        [SupportedLanguage.PL]: "Nazwisko",
    },
    ProfilePicture: {
        [SupportedLanguage.EN]: "Profile Picture",
        [SupportedLanguage.NL]: 'Profielfoto',
        [SupportedLanguage.PL]: "Zdjęcie Profilowe",
    },
    Optional: {
        [SupportedLanguage.EN]: "Optional",
        [SupportedLanguage.NL]: 'Optioneel',
        [SupportedLanguage.PL]: "Opcjonalny",
    },
    Austria: {
        [SupportedLanguage.EN]: "Austria",
        [SupportedLanguage.NL]: 'Oostenrijk',
        [SupportedLanguage.PL]: "Austria",
    },
    Belgium: {
        [SupportedLanguage.EN]: "Belgium",
        [SupportedLanguage.NL]: 'België',
        [SupportedLanguage.PL]: "Belgia",
    },
    Bulgaria: {
        [SupportedLanguage.EN]: "Bulgaria",
        [SupportedLanguage.NL]: 'Bulgarije',
        [SupportedLanguage.PL]: "Bułgaria",
    },
    Croatia: {
        [SupportedLanguage.EN]: "Croatia",
        [SupportedLanguage.NL]: 'Kroatië',
        [SupportedLanguage.PL]: "Chorwacja",
    },
    Cyprus: {
        [SupportedLanguage.EN]: "Cyprus",
        [SupportedLanguage.NL]: 'Cyprus',
        [SupportedLanguage.PL]: "Cypr",
    },
    Czech: {
        [SupportedLanguage.EN]: "Czech Republic",
        [SupportedLanguage.NL]: 'Tsjechische Republiek',
        [SupportedLanguage.PL]: "Republika Czeska",
    },
    Denmark: {
        [SupportedLanguage.EN]: "Denmark",
        [SupportedLanguage.NL]: 'Denemarken',
        [SupportedLanguage.PL]: "Dania",
    },
    Estonia: {
        [SupportedLanguage.EN]: "Estonia",
        [SupportedLanguage.NL]: 'Estland',
        [SupportedLanguage.PL]: "Estonia",
    },
    Finland: {
        [SupportedLanguage.EN]: "Finland",
        [SupportedLanguage.NL]: 'Finland',
        [SupportedLanguage.PL]: "Finlandia",
    },
    France: {
        [SupportedLanguage.EN]: "France",
        [SupportedLanguage.NL]: 'Frankrijk',
        [SupportedLanguage.PL]: "Francja",
    },
    Germany: {
        [SupportedLanguage.EN]: "Germany",
        [SupportedLanguage.NL]: 'Duitsland',
        [SupportedLanguage.PL]: "Niemcy",
    },
    Greece: {
        [SupportedLanguage.EN]: "Greece",
        [SupportedLanguage.NL]: 'Griekenland',
        [SupportedLanguage.PL]: "Grecja",
    },
    Hungary: {
        [SupportedLanguage.EN]: "Hungary",
        [SupportedLanguage.NL]: 'Hongarije',
        [SupportedLanguage.PL]: "Węgry",
    },
    Ireland: {
        [SupportedLanguage.EN]: "Ireland",
        [SupportedLanguage.NL]: 'Ierland',
        [SupportedLanguage.PL]: "Irlandia",
    },
    Italy: {
        [SupportedLanguage.EN]: "Italy",
        [SupportedLanguage.NL]: 'Italië',
        [SupportedLanguage.PL]: "Włochy",
    },
    Latvia: {
        [SupportedLanguage.EN]: "Latvia",
        [SupportedLanguage.NL]: 'Letland',
        [SupportedLanguage.PL]: "Łotwa",
    },
    Lithuania: {
        [SupportedLanguage.EN]: "Lithuania",
        [SupportedLanguage.NL]: 'Litouwen',
        [SupportedLanguage.PL]: "Litwa",
    },
    Luxembourg: {
        [SupportedLanguage.EN]: "Luxembourg",
        [SupportedLanguage.NL]: 'Luxemburg',
        [SupportedLanguage.PL]: "Luksemburg",
    },
    Malta: {
        [SupportedLanguage.EN]: "Malta",
        [SupportedLanguage.NL]: 'Malta',
        [SupportedLanguage.PL]: "Malta",
    },
    Netherlands: {
        [SupportedLanguage.EN]: "Netherlands",
        [SupportedLanguage.NL]: 'Nederland',
        [SupportedLanguage.PL]: "Holandia",
    },
    Poland: {
        [SupportedLanguage.EN]: "Poland",
        [SupportedLanguage.NL]: 'Polen',
        [SupportedLanguage.PL]: "Polska",
    },
    Portugal: {
        [SupportedLanguage.EN]: "Portugal",
        [SupportedLanguage.NL]: 'Portugal',
        [SupportedLanguage.PL]: "Portugalia",
    },
    Romania: {
        [SupportedLanguage.EN]: "Romania",
        [SupportedLanguage.NL]: 'Roemenië',
        [SupportedLanguage.PL]: "Rumunia",
    },
    Slovakia: {
        [SupportedLanguage.EN]: "Slovakia",
        [SupportedLanguage.NL]: 'Slowakije',
        [SupportedLanguage.PL]: "Słowacja",
    },
    Slovenia: {
        [SupportedLanguage.EN]: "Slovakia",
        [SupportedLanguage.NL]: 'Slovenië',
        [SupportedLanguage.PL]: "Słowenia",
    },
    Spain: {
        [SupportedLanguage.EN]: "Spain",
        [SupportedLanguage.NL]: 'Spanje',
        [SupportedLanguage.PL]: "Hiszpania",
    },
    Sweden: {
        [SupportedLanguage.EN]: "Sweden",
        [SupportedLanguage.NL]: 'Zweden',
        [SupportedLanguage.PL]: "Szwecja",
    },
    Country: {
        [SupportedLanguage.EN]: "Country",
        [SupportedLanguage.NL]: 'Land',
        [SupportedLanguage.PL]: "Kraj",
    },
    Countries: {
        [SupportedLanguage.EN]: "Countries",
        [SupportedLanguage.NL]: 'Landen',
        [SupportedLanguage.PL]: "Kraje",
    },
    AddEmployee: {
        [SupportedLanguage.EN]: "Add an employee",
        [SupportedLanguage.NL]: 'Voeg een medewerker toe',
        [SupportedLanguage.PL]: "Dodaj pracownika",
    },
    Roster: {
        [SupportedLanguage.EN]: "Roster",
        [SupportedLanguage.NL]: 'Rooster',
        [SupportedLanguage.PL]: "Lista",
    },
    Group: {
        [SupportedLanguage.EN]: "Group",
        [SupportedLanguage.NL]: 'Groep',
        [SupportedLanguage.PL]: "Grupa",
    },
    Groups: {
        [SupportedLanguage.EN]: "Groups",
        [SupportedLanguage.NL]: 'Groepen',
        [SupportedLanguage.PL]: "Grupy",
    },
}

export const i18nDays = {
    "short": {
        [SupportedLanguage.EN]: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        [SupportedLanguage.NL]: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
        [SupportedLanguage.PL]: ["Ni", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
    },
    "long": {
        [SupportedLanguage.EN]: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        [SupportedLanguage.NL]: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
        [SupportedLanguage.PL]: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
    },
}